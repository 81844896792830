import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const DesignsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} location={location}>
      <SEO
        title="Designs"
      />

      {/*<article className="post-content page-template no-image">*/}
      {/*  <div className="post-content-body">*/}
      {/*    <figure className="kg-card kg-image-card">*/}
      {/*      <GatsbyImage*/}
      {/*        alt={""}*/}
      {/*        image={data.smallPic.childImageSharp.gatsbyImageData}*/}
      {/*        className="kg-image" />*/}
      {/*      <figcaption>Regular image</figcaption>*/}
      {/*    </figure>*/}
      {/*    <figure className="kg-card kg-image-card kg-width-wide">*/}
      {/*      <GatsbyImage image={data.medPic.childImageSharp.gatsbyImageData} className="kg-image" alt={""}/>*/}
      {/*      <figcaption>Large image</figcaption>*/}
      {/*    </figure>*/}
      {/*    <figure className="kg-card kg-image-card kg-width-full">*/}
      {/*      <GatsbyImage*/}
      {/*        image={data.largePic.childImageSharp.gatsbyImageData}*/}
      {/*        className="kg-image"*/}
      {/*        alt={""}/>*/}
      {/*      <figcaption>Full bleed image</figcaption>*/}
      {/*    </figure>*/}
      {/*  </div>*/}
      {/*</article>*/}
    </Layout>
  );
}

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  smallPic: file(relativePath: {eq: "fabio-comparelli-696506-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  medPic: file(relativePath: {eq: "sophia-valkova-30139-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  largePic: file(relativePath: {eq: "vladimir-malyutin-98174-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const Designs = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <DesignsPage location={props.location} data={data} {...props} />
    )}
  />
)
export default Designs
